import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Formik } from 'formik'
import { Button, Form, FormGroup, Label, Spinner } from 'reactstrap'
import { customInputForm } from 'ui/common'
import { COUNTRIES } from 'export'
import { getOrgBySlug, updateOrg, updateOrgImage } from 'features/dashboard/Organization.actionTypes'
import { removeNullFromObj } from 'utils'
import { handleDrag, handleDrop, handleFileChange } from './OrgTeamHelpers'

const OrganizationDetails = ({ organisation }) => {
    const dispatch = useDispatch()
    const [dragActive, setDragActive] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const inputFile = useRef(null)

    const handleUpdateOrg = (data, { setErrors, setSubmitting }) => {
        setSubmitting(true)
        if (data.image === organisation.image || !data.image) {
            delete data.image
            const sortedData = removeNullFromObj(data)
            const sortedAddress = removeNullFromObj(sortedData.address)
            sortedData.address = sortedAddress
            dispatch(updateOrg({ uuid: organisation.uuid, data: sortedData })).then(({ error }) => {
                if (error) {
                    setErrors(error.response.data)
                    setSubmitting(false)
                } else {
                    setSubmitting(false)
                    dispatch(getOrgBySlug(organisation))
                }
            })
        } else {
            const formData = new FormData()
            formData.append('image', data.image)

            dispatch(updateOrgImage({ slug: organisation.slug, data: formData })).then(
                ({ error }) => {
                    if (error) {
                        setErrors(error.response.data)
                        setSubmitting(false)
                    } else {
                        delete data.image
                        const sortedData = removeNullFromObj(data)
                        dispatch(updateOrg({ uuid: organisation.uuid, data: sortedData })).then(
                            ({ error }) => {
                                if (error) {
                                    setErrors(error.response.data)
                                    setSubmitting(false)
                                } else {
                                    setSubmitting(false)
                                    dispatch(getOrgBySlug(organisation))
                                }
                            }
                        )
                    }
                }
            )
        }
    }

    return (
        <div>
            <div data-testid="orgdiv">
                <h4>Organisation details</h4>
                <span>Update your organisation details and photo here.</span>
            </div>
            <hr />
            <Formik
                initialValues={organisation}
                enableReinitialize={true}
                onSubmit={(data, action) => {
                    handleUpdateOrg(data, action)
                }}
            >
                {({ values, isSubmitting, setFieldValue, handleSubmit }) => (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}
                    >
                        <div
                            className="d-flex justify-content-between align-items-start"
                            style={{ padding: '0px 360px 32px 0px' }}
                        >
                            <Label for="name" style={{ fontWeight: 'bold' }}>
                                Organisation name
                            </Label>
                            <div className="d-flex justify-content-center align-items-center mt-2">
                                <FormGroup>
                                    <Field
                                        id="name"
                                        component={customInputForm}
                                        type="text"
                                        name="title"
                                        placeholder="Organisation name"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values?.title || ''}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ padding: '0px 360px 32px 0px' }}
                        >
                            <Label for="email" style={{ fontWeight: 'bold' }}>
                                Email
                            </Label>
                            <div className="d-flex justify-content-center align-items-center mt-2">
                                <FormGroup>
                                    <Field
                                        id="email"
                                        name="default_email"
                                        type="email"
                                        placeholder="Email"
                                        component={customInputForm}
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values?.default_email || ''}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-start"
                            style={{ padding: '0px 360px 32px 0px' }}
                        >
                            <Label for="usernmae" style={{ fontWeight: 'bold' }}>
                                Address
                            </Label>
                            <div className="d-flex flex-column justify-content-center align-items-center mt-2">
                                <FormGroup>
                                    <Label className="modals-labels" style={{ fontWeight: 'bold' }}>
                                        Address
                                    </Label>
                                    <Field
                                        component={customInputForm}
                                        type="text"
                                        name="address.address1"
                                        placeholder="Address"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values.address?.address1 || ''}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels" style={{ fontWeight: 'bold' }}>
                                        Apartment, suite, etc. (optional)
                                    </Label>
                                    <Field
                                        component={customInputForm}
                                        type="text"
                                        name="address.address2"
                                        placeholder="Apartment, suite, etc. (optional)"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values.address?.address2 || ''}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels" style={{ fontWeight: 'bold' }}>
                                        City
                                    </Label>
                                    <Field
                                        component={customInputForm}
                                        type="text"
                                        name="address.city"
                                        placeholder="City"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values.address?.city || ''}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label
                                        className="modals-labels"
                                        style={{ fontWeight: 'bold' }}
                                        for="country"
                                    >
                                        Country
                                    </Label>
                                    <Field
                                        component={customInputForm}
                                        type="select"
                                        name="address.country_code"
                                        placeholder="Country"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '0px 8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values.address?.country_code || ''}
                                    >
                                        <option value=""></option>
                                        {COUNTRIES.map((country, key) => (
                                            <option value={country.code} key={key}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </Field>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels" style={{ fontWeight: 'bold' }}>
                                        Zip/postal code
                                    </Label>
                                    <Field
                                        component={customInputForm}
                                        type="text"
                                        name="address.zip_code"
                                        placeholder="Zip/postal code"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '2rem',
                                            width: '28rem',
                                        }}
                                        value={values.address?.zip_code || ''}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <hr />
                        <div
                            className="d-flex justify-content-between align-items-start"
                            style={{ padding: '0px 360px 32px 0px' }}
                        >
                            <div className="d-flex flex-column">
                                <Label for="image" style={{ fontWeight: 'bold' }}>
                                    Logo
                                </Label>
                                <span>This will be displayed on your profile</span>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                {imageUrl && organisation.image !== values.image ? (
                                    <div
                                        className="rounded-circle"
                                        style={{
                                            width: '72px',
                                            height: '72px',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <img
                                            id="user"
                                            src={imageUrl}
                                            alt="user"
                                            width={72}
                                            height={72}
                                            style={{
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                ) : values?.image ? (
                                    <div
                                        className="rounded-circle"
                                        style={{
                                            width: '72px',
                                            height: '72px',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <img
                                            id="user"
                                            src={values.image}
                                            alt="user"
                                            width={72}
                                            height={72}
                                            style={{
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <img
                                        id="user"
                                        src="/svgs/page-title-icons/user.svg"
                                        alt="user"
                                        width={72}
                                        height={72}
                                    />
                                )}
                                <div
                                    className={`d-flex flex-column justify-content-center align-items-center p-5 border rounded ml-2 ${
                                        dragActive ? 'border-gray' : ' border-meili'
                                    }`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => inputFile.current.click()}
                                    onDragEnter={(e) => handleDrag(e, setDragActive)}
                                    onDragLeave={(e) => handleDrag(e, setDragActive)}
                                    onDragOver={(e) => handleDrag(e, setDragActive)}
                                    onDrop={(e) =>
                                        handleDrop(e, setFieldValue, setDragActive, setImageUrl)
                                    }
                                >
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={inputFile}
                                        accept={'.png, .pgm, .svg, .jpg'}
                                        onChange={(event) =>
                                            handleFileChange(event, setFieldValue, setImageUrl)
                                        }
                                    />
                                    <img
                                        id="upload"
                                        src="/svgs/settings-page/upload.svg"
                                        alt="user"
                                        width={48}
                                        height={48}
                                    />

                                    <span className="my-2" style={{ fontWeight: 'bold' }}>
                                        <span className="text-meili">Click to upload</span> or drag
                                        an drop
                                    </span>
                                    <span>JPG, PNG or SVG (max. 400 x 400px)</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-end mr-3 my-4">
                            <Button
                                type="submit"
                                className="save-btn-modal modals-new-btns"
                                style={{ width: '5rem', height: '2.5rem' }}
                                disabled={JSON.stringify(values) === JSON.stringify(organisation)}
                            >
                                Save
                                {isSubmitting && <Spinner size="sm" color="white ml-2" />}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default OrganizationDetails
