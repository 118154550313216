import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap'

import {
    getNotifications,
    markAllRead,
    deleteAll,
    markRead,
    deleteOne,
} from 'features/dashboard/Notification.actionTypes'
import { getNotification } from 'features/dashboard/selectors'
import NotificationBody from './NotificationBody'
import Paginator from './Paginator'
import { getPaginationPages } from 'utils'

const NotificationCard = ({ toggle, notificationOpen }) => {
    const notification = useSelector(getNotification)
    const [currentPage, setCurrentPage] = useState(0)
    const dispatch = useDispatch()
    const [pagination, setPagination] = useState([])

    useEffect(() => {
        const pagesNumbers = getPaginationPages(currentPage, notification.items)
        setPagination([...pagesNumbers])
    }, [currentPage, notification.items])

    useEffect(() => {
        dispatch(getNotifications())
    }, [dispatch])

    useEffect(() => {
        if (notificationOpen) {
            setTimeout(() => {
                dispatch(markAllRead())
            }, 1000)
                
        }
    } , [notificationOpen, dispatch])

    const { status, items } = notification
    return (
        <Card
            className="card-box shadow-none border-0"
            style={{
                height: '600px',
                overflowY: notification.items.length > 0 ? 'scroll' : 'hidden',
                scrollbarWidth: 'none',
            }}
        >
            <CardHeader
                className="d-flex justify-content-between align-items-center position-sticky top-0"
                style={{ padding: '12px 24px' }}
            >
                <span
                    style={{
                        color: '#3D414C',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.2px',
                    }}
                >
                    Notifications
                </span>
                <div>
                    <img
                        style={{ cursor: 'pointer' }}
                        id="delete-all"
                        src="/svgs/organization/buttons/trash.svg"
                        alt="trash"
                        width="16px"
                        height="16px"
                        onClick={() => {
                            dispatch(deleteAll())
                        }}
                    />
                    <UncontrolledTooltip
                        placement="auto"
                        target="delete-all"
                        style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
                    >
                        Delete all
                    </UncontrolledTooltip>
                    <div
                        style={{
                            display: 'inline-block',
                            width: '1px',
                            height: '24px',
                            backgroundColor: '#E0E0E0',
                            margin: '0 12px',
                            verticalAlign: 'middle',
                        }}
                    ></div>
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
            </CardHeader>
            <CardBody style={{ padding: '16px 24px 16px 10px' }}>
                <NotificationBody
                    items={items[currentPage]}
                    status={status}
                    handleDelete={(id) => {
                        dispatch(deleteOne({ id })).then(() => {
                            dispatch(getNotifications())
                        })
                    }}
                    handleRead={(id) => dispatch(markRead({ id }))}
                />
                <div className="pb-3 pl-3">
                    {notification.items.length > 1 && status !== 'loading' && (
                        <Paginator
                            totalPages={notification.items.length}
                            pages={pagination}
                            activePage={currentPage}
                            handlePageChange={(page) => setCurrentPage(page)}
                        />
                    )}
                </div>
            </CardBody>
        </Card>
    )
}
export default NotificationCard
