import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, ButtonGroup, Button } from 'reactstrap'
import { Loading } from 'ui/common'
import { selectors } from 'features/dashboard'
import { getTeamVehicles } from 'features/dashboard/Vehicle.actionTypes'
import { getWhitedevDashboard } from 'features/dashboard/selectors'
import { TITLE } from 'config'
import Battery from './Battery'
import WhitelabelVehicle from './WhitelabelVehicle'
import { getTeamAnalytics } from 'features/dashboard/TeamAnalytics.actionTypes'
import { getDevices } from 'features/dashboard/RosSetup.actionTypes'
import ColumnEditor from 'layout-components/ColumnEditor'
import { AgGridReact } from 'ag-grid-react'

const StatusBadge = ({ backgroundColor, color, text }) => (
    <div className="d-flex justify-content-start align-items-center pt-2">
        <div
            className="rounded-pill px-2 d-flex justify-content-center align-items-center"
            style={{
                backgroundColor,
                color,
                height: '1.5rem',
            }}
        >
            {text}
        </div>
    </div>
)

export const StatusDevice = ({ device }) => {
    const statusProps = device.online
        ? { backgroundColor: '#ECFDF3', color: '#05603A', text: 'Online' }
        : { backgroundColor: '#F9FAFB', color: '#4E5462', text: 'Offline' }

    return <StatusBadge {...statusProps} />
}

export const Status = ({ vehicle }) => {
    const getStatusProps = () => {
        if (vehicle.is_online) {
            if (localStorage.getItem('nuke') === 'stopped') {
                return { backgroundColor: '#FEE4E2', color: '#912018', text: 'Emergency stop' }
            } else if (vehicle.vehicle_state === 'waiting_for_pose') {
                return { backgroundColor: '#FFF8E1', color: '#B68B00', text: 'Waiting for pose' }
            } else if (vehicle.is_charging) {
                return { backgroundColor: '#FFFAEB', color: '#DC6803', text: 'Charging' }
            } else if (vehicle.is_on_task) {
                return { backgroundColor: '#EEF4FF', color: '#175CD3', text: 'In use' }
            } else {
                return { backgroundColor: '#ECFDF3', color: '#05603A', text: 'Online' }
            }
        } else {
            return { backgroundColor: '#F9FAFB', color: '#4E5462', text: 'Offline' }
        }
    }

    return <StatusBadge {...getStatusProps()} />
}

export const vehicleDetails = ({ vehicle }) => {
    if (!vehicle.details) return { coordinates: '', formattedRotation: '', formattedStation: '' }

    const { xm, ym, lat, lon, rotation } = vehicle.details
    const formattedStation = vehicle.current_station || ''

    if (lat && lon) {
        return {
            coordinates: `Lat: ${lat} - Lon: ${lon}`,
            formattedRotation: '',
            formattedStation,
        }
    }

    const formattedXm = xm ? `X: ${parseFloat(xm).toFixed(2)}` : ''
    const formattedYm = ym ? `Y: ${parseFloat(ym).toFixed(2)}` : ''
    const formattedRotation = rotation ? `R: ${parseFloat(rotation).toFixed(2)}°` : ''

    return {
        coordinates: `${formattedXm} ${formattedYm}`.trim(),
        formattedRotation,
        formattedStation,
    }
}

const VehicleManage = ({ match }) => {
    const { slug } = match.params
    const dispatch = useDispatch()
    const vehicles = useSelector(selectors.getVehicles)
    const devices = useSelector(selectors.getDevicess)
    const whitedevDashboard = useSelector(getWhitedevDashboard)
    const [activeTab, setActiveTab] = useState('vehicles')
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPageVeh, setLastClickedPageVeh] = useState(1)
    const [searchText, setSearchText] = useState('')
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const gridRef = useRef()

    const parseData = JSON.parse(localStorage.getItem('columns-vehicles'))
    const parsedeviceData = JSON.parse(localStorage.getItem('columns-devices'))
    const [api, setApi] = useState(null)
    const onGridReady = (params) => {
        gridRef.current = params.api // Store grid API in gridRef
        params.api.sizeColumnsToFit()
        setApi(params.api)
        currentPageNumber > 1 && params.api.paginationGoToPage(lastClickedPageVeh)
    }

    const getCurrentPage = () => {
        if (api && !api.isDestroyed()) {
            const currentPage = api.paginationGetCurrentPage() + 1 // Add 1 to convert to 1-based index
            setLastClickedPageVeh(currentPage)
        }
    }
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase()
        setSearchText(value)
        api && api.onFilterChanged(value)
    }
    const defaultColDef = {
        cellClass: 'custom-cell mt-2', // Apply custom class to all cells
        headerClass: 'custom-header',
        resizable: true,
        sortable: true,
        flex: 1, // Apply custom class to all headers
        valueFormatter: (params) => {
            return params.value
        },
    }

    const restoredCol = parseData?.map((colDef) => {
        switch (colDef.field) {
            case 'location':
                return {
                    ...colDef,
                    valueFormatter: (params) => {
                        return params.value
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data.location}</div>
                    },
                }
            case 'vehicleType':
                return {
                    ...colDef,
                    cellRenderer: (params) => {
                        return <div>{params.data.vehicleType}</div>
                    },
                    valueFormatter: (params) => {
                        return params.value
                    },
                }
            case 'status':
                return {
                    ...colDef,
                    cellRenderer: (params) => {
                        return <div>{params.data.status}</div>
                    },
                    valueFormatter: (params) => {
                        return params.value
                    },
                }
            case 'battery':
                return {
                    ...colDef,
                    cellRenderer: (params) => {
                        return <div>{params.data.battery}</div>
                    },
                    valueFormatter: (params) => {
                        return params.value
                    },
                }
            default:
                return colDef
        }
    })

    const restoredDeviceDefs = parsedeviceData?.map((colDef) => {
        if (colDef.field === 'status') {
            return {
                ...colDef,
                cellRenderer: (params) => {
                    return <div>{params.data.status}</div>
                },
                valueFormatter: (params) => {
                    return params.value
                },
            }
        }
        return colDef
    })

    const [columnsList, setColumnsList] = useState(
        (parseData && restoredCol) || [
            {
                field: 'verboseName',
                headerName: 'Name',
                sortable: true,
                unSortIcon: true,
                hide: false,
                minWidth: 10,
            },
            {
                field: 'vehicleType',
                headerName: 'Type',
                hide: false,
                minWidth: 50,
            },
            {
                field: 'category_name',
                headerName: 'Category',
                hide: false,
                minWidth: 50,
            },
            {
                field: 'location',
                headerName: 'Location',
                hide: false,
                minWidth: 200,
                valueFormatter: (params) => {
                    return params.value
                },
                cellRenderer: (params) => {
                    return <div>{params.data.location}</div>
                },
            },
            {
                field: 'station',
                headerName: 'Station',
                hide: false,
                minWidth: 50,
                valueFormatter: (params) => {
                    return params.value
                },
            },
            {
                field: 'current_mission',
                headerName: 'Mission',
                hide: false,
                cellStyle: { marginLeft: '20px' },
            },
            {
                field: 'status',
                headerName: 'Status',
                hide: false,
                cellStyle: { marginLeft: '20px' },
                valueFormatter: (params) => {
                    return params.value
                },
                cellRenderer: (params) => {
                    return <div>{params.data.status}</div>
                },
            },
            {
                field: 'vehicle_state',
                headerName: 'State',
                hide: true,
                minWidth: 50,
            },
            {
                field: 'speed',
                headerName: 'Speed',
                hide: true,
            },
            {
                field: 'battery',
                headerName: 'Battery',
                hide: false,
                valueFormatter: (params) => {
                    return params.value
                },
                cellRenderer: (params) => {
                    return <div>{params.data.battery}</div>
                },
            },
        ]
    )

    const [devicesColumns, setDevicesColumns] = useState(
        restoredDeviceDefs || [
            {
                field: 'title',
                headerName: 'Name',
                sortable: true,
                unSortIcon: true,
                hide: false,
            },
            {
                field: 'identifier',
                headerName: 'Identifier',
                hide: false,
            },
            {
                field: 'states',
                headerName: 'State',
                hide: false,
            },
            {
                field: 'status',
                headerName: 'Online',
                valueFormatter: (params) => {
                    return params.value
                },
                cellRenderer: (params) => {
                    return <div>{params.data.status}</div>
                },
                hide: false,
            },
        ]
    )

    const toggleEdit = () => setDropdownOpen((prevState) => !prevState)

    const renderDashboard =
        whitedevDashboard.data.influx_gauges?.length !== undefined ? true : false

    useEffect(() => {
        if (activeTab === 'vehicles') {
            document.title = `${TITLE} - Vehicles manage`
        }
        if (activeTab === 'devices') {
            document.title = `${TITLE} - Devices manage`
            setLastClickedPageVeh(1)
        }
    }, [activeTab])

    useEffect(() => {
        if (renderDashboard) {
            dispatch(getTeamAnalytics({ slug }))
        }
        dispatch(getDevices({ slug }))
    }, [dispatch, slug, renderDashboard])

    useEffect(() => {
        dispatch(getTeamVehicles({ slug, text: 'detailed=true', page: currentPageNumber }))
    }, [currentPageNumber]) // eslint-disable-line

    const visibleColumns = columnsList.filter((column) => !column?.hide)
    const dataList = useMemo(() => {
        if (!vehicles?.teamVehicle) return []

        return vehicles.teamVehicle.map((vehicle) => {
            const details = vehicleDetails({ vehicle })

            return {
                uuid: vehicle.uuid,
                verboseName: vehicle.verbose_name || '',
                vehicleType: vehicle.vehicle_type?.toUpperCase() || '',
                category_name: vehicle.category_name || '',
                vehicle_state: vehicle.vehicle_state || '',
                status: <Status vehicle={vehicle} />,
                location: `${details.coordinates} ${details.formattedRotation}`.trim(),
                station: details.formattedStation,
                current_mission: vehicle.current_mission || '',
                speed:
                    vehicle.details && !isNaN(parseFloat(vehicle.details.speed))
                        ? `${parseFloat(vehicle.details.speed).toFixed(2)} m/s`
                        : '',
                battery: <Battery percentage={vehicle.battery_level} uuid={vehicle.uuid} />,
                is_active: vehicle.is_online || false,
            }
        })
    }, [vehicles.teamVehicle])

    const visibleDeviceColumns = devicesColumns?.filter((column) => !column.hide)

    const devicesList = useMemo(() => {
        if (!devices) return []
        return devices.map((device) => ({
            uuid: device.uuid,
            title: device?.title,
            identifier: device?.identifier,
            states: device?.status || '',
            status: <StatusDevice device={device} />,
        }))
    }, [devices])

    if (vehicles.status === 'loading') {
        return <Loading />
    }
    if (renderDashboard === true) {
        return <WhitelabelVehicle />
    } else
        return (
            <div>
                <div className="w-100" style={{ marginTop: '-45px' }}>
                    <h3>{activeTab === 'vehicles' ? 'Vehicles' : 'Devices'}</h3>
                </div>
                <Row className="mt-4 w-100">
                    <Col className="pb-5">
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <ButtonGroup
                                className="org-button-group"
                                style={{ width: '23rem', padding: '3px' }}
                            >
                                <Button
                                    data-testid="vehicleBtn"
                                    className={` ${
                                        activeTab === 'vehicles' ? 'org-button-selected' : ''
                                    } org-button`}
                                    style={{ height: '2.5rem', borderRadius: '6px' }}
                                    onClick={() => {
                                        setActiveTab('vehicles')
                                        setSearchText('')
                                    }}
                                >
                                    Vehicles
                                </Button>
                                <Button
                                    data-testid="deviceBtn"
                                    className={`${
                                        activeTab === 'devices' ? 'org-button-selected' : ''
                                    } org-button`}
                                    style={{ height: '2.5rem', borderRadius: '6px' }}
                                    onClick={() => {
                                        setActiveTab('devices')
                                        setSearchText('')
                                    }}
                                >
                                    Devices
                                </Button>
                            </ButtonGroup>
                            <ColumnEditor
                                toggle={toggleEdit}
                                dropdownOpen={dropdownOpen}
                                activeTab={activeTab}
                                list={activeTab === 'vehicles' ? columnsList : devicesColumns}
                                setList={
                                    activeTab === 'vehicles' ? setColumnsList : setDevicesColumns
                                }
                            />
                        </div>
                        <div
                            className="search-header"
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            {' '}
                            <input
                                type="text"
                                className="search-box"
                                placeholder="Search"
                                value={searchText}
                                onChange={handleSearch}
                            ></input>
                        </div>

                        <div className="mb-3 dash-table-container">
                            <div
                                className="rounded-table-container ag-theme-quartz"
                                style={{ width: '100%' }}
                            >
                                {activeTab === 'vehicles' ? (
                                    vehicles.teamVehicle.length > 0 ? (
                                        <AgGridReact
                                            bootstrap4={true}
                                            classes="my-custom-table"
                                            keyField="uuid"
                                            rowData={dataList}
                                            columnDefs={visibleColumns || []}
                                            onGridReady={onGridReady}
                                            rowHeight={60}
                                            pagination={true}
                                            paginationPageSize={10}
                                            paginationPageSizeSelector={[10, 20, 50, 100]}
                                            defaultColDef={defaultColDef}
                                            suppressScrollOnNewData={true}
                                            onPaginationChanged={getCurrentPage}
                                            quickFilterText={searchText}
                                            domLayout="autoHeight"
                                        />
                                    ) : (
                                        <h5 className="p-4">There are currently no vehicles.</h5>
                                    )
                                ) : null}

                                {activeTab === 'vehicles' &&
                                    vehicles.team_vehicles_next &&
                                    lastClickedPageVeh ===
                                        Math.ceil(vehicles.teamVehicle.length / 10) && (
                                        <Button
                                            style={{
                                                position: 'absolute',
                                                bottom: '0.7rem',
                                                right: '24px',
                                                borderRadius: '8px',
                                                background: '#f9fafb',
                                                color: '#4e5462',
                                            }}
                                            onClick={() => {
                                                setCurrentPageNumber(currentPageNumber + 1)
                                            }}
                                        >
                                            Load more (
                                            {vehicles.team_vehicles_count -
                                                vehicles.teamVehicle.length}
                                            )
                                        </Button>
                                    )}

                                {activeTab === 'devices' ? (
                                    devices.length > 0 ? (
                                        <AgGridReact
                                            ref={gridRef}
                                            classes="ag-header-group-cell"
                                            keyField="uuid"
                                            rowData={devicesList}
                                            columnDefs={visibleDeviceColumns || []}
                                            defaultColDef={defaultColDef}
                                            onGridReady={onGridReady}
                                            pagination={true}
                                            rowHeight={60}
                                            paginationPageSize={10}
                                            paginationPageSizeSelector={[10, 20, 50, 100]}
                                            quickFilterText={searchText}
                                            domLayout="autoHeight"
                                        />
                                    ) : (
                                        <h5 className="p-4">There are currently no devices.</h5>
                                    )
                                ) : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
}

export default VehicleManage
