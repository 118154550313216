import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Button, Form, FormGroup } from 'reactstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { HiOutlineMail } from 'react-icons/hi'
import { AgGridReact } from 'ag-grid-react'
import { Field, Formik } from 'formik'
import { customInputForm } from 'ui/common'
import { roleAndDescription } from './OrgTeamHelpers'
import { useDispatch } from 'react-redux'
import {
    createMember,
    getTeam,
    getTeamMembers,
    getUpdatedPathPlanning,
    removeTeamMember,
} from 'features/dashboard/Team.actionTypes'
import ManageOrganizationModals from './ManageOrganizationModals'
import DropdownSelect from 'layout-components/Dropdown'

const TeamManage = () => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const [team, setTeam] = useState(null)
    const [dataOnClick, setDataOnClick] = useState(null)
    const [form, setForm] = useState(null)
    const [modal, setModal] = useState(false)
    const [memberType, setMemberType] = useState('Observer')
    const toggle = () => setModal(!modal)
    const gridRef = useRef()
    const onGridReady = (params) => {
        gridRef.current = params.api // Store grid API in gridRef
        params.api.sizeColumnsToFit() // Resize columns once the grid is ready
    }

    useEffect(() => {
        const slug = location.state.slug
        dispatch(getTeam({ slug })).then(({ error, payload }) => {
            if (!error) {
                setTeam(payload.data)
                dispatch(getUpdatedPathPlanning(payload.data))
            }
        })
        dispatch(getTeamMembers(slug))
    }, [dispatch, location.state.slug])

    useEffect(() => {
        if (!modal) {
            setDataOnClick(null)
            setForm(null)
        }
    }, [modal])

    const handleSubmit = (data, { resetForm }) => {
        dispatch(createMember({ slug: team.slug, data })).then(() => {
            document.getElementById('membership_description').innerText = ''
            setMemberType('Observer')
            resetForm()
        })
    }
    const defaultColDef = {
        cellClass: 'custom-cell', // Apply custom class to all cells
        headerClass: 'custom-header', // Apply custom class to all headers
    }

    const handleRemoveFromTeam = ({ uuid }) => {
        dispatch(removeTeamMember({ uuid })).then(({ error }) => {
            if (!error) {
                dispatch(getTeam({ slug: team.slug })).then(({ error, payload }) => {
                    if (!error) {
                        setTeam(payload.data)
                    }
                })
            }
            toggle()
        })
    }

    const RenderOrgActions = ({ item, form }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{ cursor: 'pointer' }}
                src="/svgs/organization/buttons/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.remove)
                    setModal(true)
                }}
            />
            <img
                style={{
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.edit)
                    setModal(true)
                }}
            />
        </div>
    )

    const membersList = useMemo(() => {
        return team?.memberships?.map(({ user, membership_type, uuid }) => ({
            uuid: user.uuid,
            name: (
                <div className="d-flex flex-column">
                    <span>
                        {user.first_name} {user.last_name}
                    </span>
                    <span style={{ fontWeight: 'lighter' }}>{user.email}</span>
                </div>
            ),
            is_staff: user.is_staff,
            type: <div className="team-div-cell">{membership_type}</div>,

            action: (
                <RenderOrgActions
                    item={{ user, uuid, membership_type }}
                    form={{ edit: 'edit-team-member', remove: 'remove-team-member' }}
                />
            ),
        }))
    }, [team?.memberships])

    const membersColumns = [
        {
            field: 'name',
            headerName: 'Name',
            cellRenderer: (params) => {
                return <div>{params.data.name}</div>
            },
        },
        {
            field: 'is_staff',
            headerName: 'Is staff',
            cellRenderer: (params) => {
                return params.data.is_staff ? 'True' : 'False'
            },
        },
        {
            field: 'type',
            headerName: 'Type',
            cellRenderer: (params) => {
                return <div>{params.data.type}</div>
            },
        },
        {
            field: 'action',
            headerName: '',
            cellClass: 'action-style',
            maxWidth: 100,
            valueFormatter: (params)=>{
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.action}</div>
            },
        },
    ]

    return (
        <div className="d-flex" style={{ maxHeight: '100vh', overflow: 'hidden' }}>
            <div
                className="h-100 d-flex align-items-center justify-content-start p-4"
                style={{ width: '300px' }}
            >
                <span
                    style={{
                        fontWeight: '500',
                        color: '#9DA3B0',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        localStorage.setItem('org-manage-tab', 'teams')
                        history.push('/dashboard/organization/')
                    }}
                >
                    Teams
                </span>
                <img
                    src="/svgs/organization/members/arrowy-right.svg"
                    alt="arrow"
                    className="mx-3"
                />
                <span
                    className="px-3 py-1"
                    style={{
                        fontWeight: '500',
                        borderRadius: '10px',
                        backgroundColor: '#F9FAFB',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                    }}
                >
                    {team?.title || team?.slug}
                </span>
            </div>
            <div className="big-org-container w-100 pl-0 pr-5 pt-4">
                <div className="heading-container-org">
                    <div>
                        <div className="mb-2 d-flex align-items-center">
                            <span className="header-text-org">Team members</span>

                            <span
                                className="text-meili rounded px-2"
                                style={{ backgroundColor: '#F9EFFE', marginLeft: '24px' }}
                            >
                                {team?.memberships?.length} members
                            </span>
                        </div>
                        <span className="small-text-org">
                            Manage your team members and their account permissions here.
                        </span>
                    </div>
                    <div className="d-flex">
                        <Button
                            outline
                            disabled={!team}
                            className="filter-button-org org-page-btns mx-2"
                            onClick={() => {
                                setForm('team-site-settings')
                                setDataOnClick(team)
                                toggle()
                            }}
                        >
                            Site settings
                        </Button>
                        <Button
                            className="new-buttons-desing-org-purple org-page-btns text-white"
                            disabled={!team}
                            onClick={() => {
                                setForm('edit-team')
                                setDataOnClick(team)
                                toggle()
                            }}
                        >
                            Team settings
                        </Button>
                    </div>
                </div>
                <div>
                    <div className="d-flex pb-3 justify-content-between">
                        <div
                            className="d-flex flex-column"
                            style={{ width: '300px', marginRight: '24px', marginBottom: '8px' }}
                        >
                            <span
                                style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    letterSpacing: '0.1px',
                                }}
                            >
                                Invite team members
                            </span>
                            <span className="">
                                Enhance your fleet management by inviting your team to collaborate.
                            </span>
                        </div>
                        <div className="" style={{ width: '90%' }}>
                            <Formik
                                initialValues={{ email: '', membership_type: 'observer' }}
                                onSubmit={(values, actions) => handleSubmit(values, actions)}
                            >
                                {({ values, handleSubmit, setFieldValue }) => (
                                    <Form
                                        onSubmit={async (e) => {
                                            e.preventDefault()
                                            handleSubmit()
                                        }}
                                    >
                                        <FormGroup>
                                            <div className="d-flex justify-content-center align-items-center w-100">
                                                <div className="w-75 mr-1">
                                                    <Field
                                                        className="modals-inputs"
                                                        type="email"
                                                        name="email"
                                                        style={{ height: '40px' }}
                                                        placeholder="name@email.com"
                                                        component={customInputForm}
                                                    />
                                                </div>
                                                <div className="w-25 ml-1">
                                                    <DropdownSelect
                                                        selected={memberType}
                                                        setSelected={setMemberType}
                                                        options={Object.keys(
                                                            roleAndDescription['organization']
                                                        ).map((user) => {
                                                            return {
                                                                uuid: user,
                                                                title: `${user[0].toUpperCase()}${user.substring(
                                                                    1
                                                                )}`,
                                                            }
                                                        })}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue="membership_type"
                                                    />
                                                </div>
                                                {/* <Field
                                                    className="modals-inputs ml-2"
                                                    style={{ width: '180px' }}
                                                    component={customInputForm}
                                                    id="type"
                                                    name="membership_type"
                                                    type="select"
                                                    value={values.membership_type}
                                                    onChange={(e) => {
                                                        document.getElementById(
                                                            'membership_description'
                                                        ).innerText =
                                                            roleAndDescription['team'][
                                                                e.target.value
                                                            ]

                                                        setFieldValue(
                                                            'membership_type',
                                                            e.target.value
                                                        )
                                                    }}
                                                >
                                                    {Object.keys(roleAndDescription['team']).map(
                                                        (user) => (
                                                            <option key={user} value={user}>
                                                                {`${user[0].toUpperCase()}${user.substring(
                                                                    1
                                                                )}`}
                                                            </option>
                                                        )
                                                    )}
                                                </Field> */}
                                            </div>
                                            <small
                                                color="none"
                                                id="membership_description"
                                                style={{
                                                    color: 'black',
                                                }}
                                            />
                                        </FormGroup>

                                        <div className="">
                                            {/* <Button
                                                color="none"
                                                onClick={() => {
                                                    // setForm('remove-member')
                                                }}
                                                className="modals-delete-btn"
                                            >
                                                <HiOutlineTrash size={20} className="mr-1" />
                                                Delete
                                            </Button> */}

                                            <Button
                                                className="save-btn-modal modals-new-btns pull-right"
                                                type="submit"
                                                color="meili"
                                                disabled={!values.email}
                                            >
                                                <HiOutlineMail size={20} />
                                                Send invite
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex py-3 justify-content-between">
                        <div
                            className="d-flex flex-column"
                            style={{ width: '300px', marginRight: '24px', marginBottom: '8px' }}
                        >
                            <span
                                style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    letterSpacing: '0.1px',
                                }}
                            >
                                Team members
                            </span>
                            <span className="">
                                Manage your existing team and change permissions.
                            </span>
                        </div>
                        <div
                            className="rounded-table-container ag-theme-quartz"
                            style={{ height: '350px', width: '90%' }}
                        >
                            {membersList?.length > 0 ? (
                                <AgGridReact
                                    classes="my-custom-table"
                                    keyField="uuid"
                                    rowData={membersList}
                                    columnDefs={membersColumns}
                                    onGridReady={onGridReady}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={[10, 20, 50, 100]}
                                    defaultColDef={defaultColDef}
                                    rowHeight={75}
                                />
                            ) : (
                                <h5 className="p-4">There are currently no members.</h5>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ManageOrganizationModals
                slug={team?.slug}
                form={form}
                modal={modal}
                toggle={toggle}
                setForm={setForm}
                setTeam={setTeam}
                dataOnClick={dataOnClick}
                submitDelete={handleRemoveFromTeam}
            />
        </div>
    )
}

export default TeamManage
