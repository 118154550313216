import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, CardHeader } from 'reactstrap'
import { AgGridReact } from 'ag-grid-react'
import { getOrgWebhooks } from 'features/dashboard/selectors'

const WebhookManage = ({ setDataOnClick, setForm, toggle, setModal }) => {
    const webhooks = useSelector(getOrgWebhooks)
    const gridRef = useRef()
    const onGridReady = (params) => {
        gridRef.current = params.api // Store grid API in gridRef
        params.api.sizeColumnsToFit() // Resize columns once the grid is ready
    }
    const defaultColDef = {
        cellClass: 'cell-class', // Apply custom class to all cells
        headerClass: 'custom-header', // Apply custom class to all headers
    }

    const RenderWebhookManage = ({ item, form }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{ cursor: 'pointer' }}
                src="/svgs/organization/buttons/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.remove)
                    setModal(true)
                }}
            />
            <img
                style={{
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.edit)
                    setModal(true)
                }}
            />
        </div>
    )

    const webhookList = useMemo(() => {
        return webhooks.map((webhook) => ({
            uuid: webhook.uuid,
            url: webhook.url,
            actions: webhook?.action_list?.join(', ') || '-',
            headers:
                (
                    <div className="d-flex flex-column">
                        <span>
                            keys: {webhook?.headers?.map((header) => header.key || '-').join(', ')}
                        </span>
                        <span>
                            values:{' '}
                            {webhook?.headers?.map((header) => header.value || '-').join(', ')}
                        </span>
                    </div>
                ) || '-',
            action: (
                <RenderWebhookManage
                    item={webhook}
                    form={{ edit: 'edit-webhook', remove: 'remove-webhook' }}
                />
            ),
        }))
    }, [webhooks])

    const columnsList = [
        {
            field: 'url',
            headerName: 'Url',
            sortable: true,
            unSortIcon: true,
        },
        {
            field: 'actions',
            headerName: 'Actions',
        },
        {
            field: 'headers',
            headerName: 'Headers',
            valueFormatter: (params)=>{
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.headers}</div>
            },
        },
        {
            field: 'action',
            headerName: '',
            cellClass: 'action-style',
            maxWidth: 100,
            valueFormatter: (params)=>{
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.action}</div>
            },
        },
    ]
    return (
        <Card className="card-box border-1">
            <CardHeader className="table-header-webhooks">
                <div className="d-flex align-items-start" style={{ gap: '24px' }}>
                    <h5 className="card-header-title">Webhooks</h5>
                    <span
                        className="text-meili rounded px-2"
                        style={{ backgroundColor: '#F9EFFE' }}
                    >
                        {webhooks?.length} webhooks
                    </span>
                </div>
                <div className="d-flex">
                    {/* <Button outline id="filterId" className="filter-button-org org-page-btns mx-2">
                        <img
                            src="/svgs/organization/buttons/filter.svg"
                            width={16}
                            height={10}
                            alt="filter"
                        />
                        Filters
                    </Button> */}
                    <Button
                        className="new-buttons-desing-org-purple org-page-btns text-white"
                        onClick={() => {
                            setForm('add-webhook')
                            toggle()
                        }}
                        style={{ height: '40px' }}
                    >
                        <img src="/svgs/trigger-actions/add.svg" alt="plus" />
                        Add webhook
                    </Button>
                </div>
            </CardHeader>

            {webhooks?.length === 0 ? (
                <h5 className="p-4">There are currently no webhooks.</h5>
            ) : (
                <div
                    className="rounded bg-white w-100 pb-2 ag-theme-quartz"
                    style={{ height: '530px'}}
                >
                    <AgGridReact
                        classes="my-custom-table"
                        keyField="uuid"
                        rowData={webhookList}
                        columnDefs={columnsList}
                        onGridReady={onGridReady}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20, 50, 100]}
                        defaultColDef={defaultColDef}
                        rowHeight={70}
                    />
                </div>
            )}
        </Card>
    )
}

export default WebhookManage
