import axios from 'axios'
import { multiClientMiddleware } from 'redux-axios-middleware'
import { setMessages } from 'features/generic/Message.actionTypes'

import { API_ROOT } from 'config'
const token = window.localStorage.getItem('token')

export const authorization = `Bearer ${token}`

const headers = () => {
    const headers = {
        'Content-Type': 'application/json',
    }

    if (token && token.length > 0) {
        headers.Authorization = authorization
    }
    return headers
}
export const fileClient = axios.create({
    baseURL: API_ROOT,
    responseType: 'blob',
    timeout: 30000,
    headers: headers(),
})

export const fileUpload = axios.create({
    baseURL: API_ROOT,
    responseType: 'json',
    timeout: 30000,
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: authorization,
    },
})

const authClient = axios.create({
    baseURL: API_ROOT,
    responseType: 'json',
    headers: headers(),
})

const guestClient = axios.create({
    baseURL: API_ROOT,
    responseType: 'json',
})

export const startAndStopSimulation = axios.create({
    baseURL: API_ROOT,
    responseType: 'json',
    withCredentials: false,
    headers: headers(),
})

const thirdParty = axios.create()

const config = {
    interceptors: {
        response: [
            {
                error: function ({ dispatch }, error) {
                    const { status } = error.response
                    let message = null

                    if (status === 500) {
                        message = 'Internal Server error, please try again'
                        error = {
                            response: { data: { non_field_errors: [message] } },
                        }
                    }

                    if (message) {
                        dispatch(
                            setMessages([
                                {
                                    type: 'error',
                                    message,
                                    autoDismiss: true,
                                },
                            ])
                        )
                    }
                    return Promise.reject(error)
                },
            },
        ],
    },
}

export default multiClientMiddleware({
    default: {
        client: authClient,
        options: config,
    },
    guest: {
        client: guestClient,
        options: config,
    },
    thirdParty: {
        client: thirdParty,
    },
})
