/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as moment from 'moment'
import {
    Button,
    Popover,
    Modal,
    ModalBody,
    ButtonGroup,
    Col,
    Input,
    FormGroup,
    Label,
} from 'reactstrap'
import {
    MissionStatus,
    MissionDeliveryStatus,
    TotalDeliveries,
    TotalErrors,
    TotalMission,
    RobotStatus,
    MissionSuccess,
} from './DashboardCardsV2'
import {
    getAnalytics,
    getDashViews,
    getFilteredData,
    getSavedViewData,
    getTeam,
    getUser,
    getVehiclesCategoires,
} from 'features/dashboard/selectors'
import {
    getDashboardView,
    getDashboardCard,
    filterDashboard,
} from 'features/dashboard/TeamAnalytics.actionTypes'
import NotificationCard from './Notifications'
import { BiChevronDown } from 'react-icons/bi'
import { DateRangePicker } from 'react-date-range'
import AddCard from './AddCard'
import { getTeamVehicles, getVehicleCategories } from 'features/dashboard/Vehicle.actionTypes'
import NewDashViews from './NewDashViews'
import { Loading } from 'ui/common'
import addbtn from './../../assets/images/illustrations/addbtn.svg'
import dots from './../../assets/images/illustrations/dots.svg'
import filter from './../../assets/images/illustrations/filter.svg'
import pagesetting from './../../assets/images/illustrations/pagesetting.svg'
import exportdata from './../../assets/images/illustrations/exportdata.svg'
import { fileClient } from 'middleware/api'
import DashboardPagesetting from './DashboardPageSetting'
import { getTeamNotifications } from 'features/dashboard/Notification.actionTypes'
import { TITLE } from 'config'

const DashboardHome = ({ match }) => {
    const dispatch = useDispatch()
    const analytics = useSelector(getAnalytics)
    const dashboardView = useSelector(getDashViews)
    const savedViewCards = useSelector(getSavedViewData)
    const filteredData = useSelector(getFilteredData)
    const categories = useSelector(getVehiclesCategoires)
    const team = useSelector(getTeam)
    const userInfo = useSelector(getUser)
    const { slug } = match.params
    // const [isdefault, setDefault] = useState(true)
    const defaultId = Object.values(dashboardView)?.map((point) => point.uuid)
    const [uuid, setId] = useState(null)
    const [addcard, setAddcard] = useState(false)
    const [setting, setSetting] = useState(false)
    const [checkedList, setCheckedList] = useState([])
    const [pagesettingOpen, setPagesetting] = useState(false)
    const [vehFilter, setFilter] = useState(false)
    const [datepicker, setDatepicker] = useState(false)
    const settingToggle = () => setPagesetting((prevState) => !prevState)
    const [list, setList] = useState()
    const [save, setSave] = useState(false)
    const [isPreset, setPreset] = useState(false)

    const preset = Object.values(dashboardView)?.map((point) => point.is_preset)
    const [view, setView] = useState(Object.values(dashboardView)?.map((point) => point))
    const [createView, setCreateView] = useState(false)
    const [isfilter, setfilter] = useState(filteredData)
    var sortedArray = []

    const [dashitems, setDashItems] = useState(sortedArray)
    const [modal, setModal] = useState(false)
    const modalToggle = () => {
        setModal(!modal)
    }
    const [isOpen, setIsopen] = useState()

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true)
    }

    const datetoggle = () => setDatepicker((prevState) => !prevState)
    const [isSelectedDate, setIsSelectedDate] = useState(false)
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        today: 'today',
        week: 'week',
        key: 'selection',
    })
    const sDate = moment(selectedDateRange.startDate).format('YYYY-MM-DD')
    const eDate = moment(selectedDateRange.endDate).format('YYYY-MM-DD')

    const [modalType, setModalType] = useState('')
    let Difference_In_Time =
        selectedDateRange.endDate.getTime() - selectedDateRange.startDate.getTime()

    let selectedDays = Math.round(Difference_In_Time / (1000 * 3600 * 24))

    useEffect(() => {
        document.title = `${TITLE} - Dashboard`
    }, [])

    useEffect(() => {
        setPreset(preset[0])
    }, [preset[0]]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setId(defaultId[0])
    }, [defaultId[0]]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setfilter(filteredData)
    }, [filteredData, dispatch, slug])

    useEffect(() => {
        setList(savedViewCards)
    }, [savedViewCards])

    useEffect(() => {
        setDashItems(sortedArray)
    }, [savedViewCards, dispatch, uuid]) // eslint-disable-line react-hooks/exhaustive-deps

    // Combine related API calls for dashboard data
    useEffect(() => {
        dispatch(getDashboardView({ slug }))
        dispatch(getTeamVehicles({ slug, text: 'detailed=true' }))
        dispatch(getVehicleCategories({ slug }))
        if (team.details?.uuid) dispatch(getTeamNotifications({ uuid: team.details?.uuid }))
    }, [slug, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const from_date = sDate
        const to_date = eDate

        uuid &&
            dispatch(filterDashboard({ slug, uuid, from_date, to_date })).then((res) => {
                dispatch(getDashboardCard({ slug, uuid }))
            })
    }, [uuid]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelect = (ranges) => {
        setIsSelectedDate(true)
        setSelectedDateRange(ranges.selection)
    }
    const updateDate = () => {
        const from_date = sDate
        const to_date = eDate
        dispatch(filterDashboard({ slug, uuid, from_date, to_date })).then((res) => {
            dispatch(getDashboardCard({ slug, uuid }))
        })
        datetoggle()
    }

    var start = moment(selectedDateRange?.startDate).format('DD MMM YYYY')
    var end = moment(selectedDateRange?.endDate).format('DD MMM YYYY')
    const handleselect = (event) => {
        const value = event.target.value
        const isChecked = event.target.checked

        if (isChecked) {
            //Add checked item into checkList
            setCheckedList([...checkedList, value])
        } else {
            //Remove unchecked item from checkList
            const filteredList = checkedList.filter((item) => item !== value)
            setCheckedList(filteredList)
        }
    }
    const saveVehicleCategory = () => {
        const from_date = sDate
        const to_date = eDate
        const vehicle_categories = checkedList
        dispatch(filterDashboard({ slug, uuid, from_date, to_date, vehicle_categories })).then(
            (res) => {
                setCheckedList([])
                dispatch(getDashboardCard({ slug, uuid }))
            }
        )
        setFilter(false)
    }

    const exportCVS = () => {
        const url = `api/v2/teams/${slug}/dashboards/${uuid}/export/`

        // Prepare data object to be sent in the body
        const data = { from_date: null, to_date: null }
        if (isSelectedDate) {
            data.from_date = sDate
            data.to_date = eDate
        }

        fileClient
            .post(url, data)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                const fileName = `Dashboard data ${start} - ${end}.csv`
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.remove()
            })
            .catch((error) => {
                // Handle error
                console.error('Error exporting CSV:', error)
            })
    }

    if (analytics.status === 'loading' || !analytics.status || !uuid) {
        return <Loading />
    }

    const allDashItems = [
        {
            name: 'Total missions',
            component: (
                <TotalMission selectedDays={selectedDays} filteredData={isfilter ? isfilter : {}} />
            ),

            class: 'col-md-4 col-sm-8 col-lg-4 card-padding',
            show: true,
        },
        {
            name: 'Total errors',
            component: (
                <TotalErrors selectedDays={selectedDays} filteredData={isfilter ? isfilter : {}} />
            ),

            class: 'col-md-4 col-sm-8 col-lg-4 card-padding',
            show: true,
        },

        {
            name: 'Total deliveries',
            component: (
                <TotalDeliveries
                    selectedDays={selectedDays}
                    filteredData={isfilter ? isfilter : {}}
                />
            ),
            class: 'col-md-4 col-sm-8 col-lg-4 card-padding',
            show: true,
        },

        {
            name: 'Mission status',
            component: (
                <MissionStatus
                    dashboardData={savedViewCards}
                    filteredData={isfilter ? isfilter : {}}
                />
            ),
            class: 'col-md-4 col-sm-8 col-lg-4 card-padding',
            show: true,
        },
        {
            name: 'Mission delivery status',
            component: (
                <MissionDeliveryStatus
                    selectedDays={selectedDays}
                    filteredData={isfilter ? isfilter : {}}
                />
            ),

            class: 'col-md-4 col-sm-8 col-lg-4 card-padding',
            show: true,
        },

        {
            name: 'Robot status',
            component: (
                <RobotStatus
                    dashboardData={savedViewCards}
                    filteredData={isfilter ? isfilter : {}}
                />
            ),
            class: 'col-md-4 col-sm-8 col-lg-4 card-padding',
            show: true,
        },
        {
            name: 'Mission success',
            component: (
                <MissionSuccess
                    dashboardData={savedViewCards}
                    filteredData={isfilter ? isfilter : {}}
                />
            ),
            class: 'col-md-4 col-sm-8 col-lg-4 card-padding',
            show: true,
        },
    ]

    for (var i = 0; i < savedViewCards?.length; i++) {
        var found = false
        for (var j = 0; j < allDashItems?.length && !found; j++) {
            if (savedViewCards[i].title === allDashItems[j].name) {
                sortedArray.push(allDashItems[j])
                found = true
            }
        }
    }

    return (
        <div>
            <div className="w-100" style={{ marginTop: '-45px' }}>
                <div className="welcome-heading">
                    Welcome back, {userInfo?.profile.first_name} {userInfo?.profile.last_name}
                </div>
                <span className="welcome-text">
                    View your fleet’s activity with our latest insights.
                </span>
            </div>
            <div className="w-100 mb-3 pr-3 d-flex justify-content-between align-items-center">
                <div className="top-items">
                    <ButtonGroup className="org-button-group">
                        {Object.values(dashboardView)?.map((view, index) => (
                            <Button
                                key={index}
                                className={`${
                                    view.uuid === uuid ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setId(view?.uuid)
                                    // setDefault(view.is_default)
                                    setPreset(view?.is_preset)
                                    setView(view)
                                    pagesettingOpen && settingToggle()
                                    datepicker && datetoggle()
                                    vehFilter && setFilter(false)
                                    isOpen && setIsopen(false)
                                }}
                            >
                                {view?.title === 'Default'
                                    ? 'System default'
                                    : view.title?.charAt(0).toUpperCase() + view.title?.slice(1)}
                            </Button>
                        ))}

                        {dashboardView.length < 3 && (
                            <img
                                data-testid="openModal"
                                alt="addbtn"
                                src={addbtn}
                                className="mx-1"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    modalToggle()
                                    setCreateView(true)
                                    setModalType('createView')
                                }}
                            ></img>
                        )}
                    </ButtonGroup>

                    <div className="d-flex justify-content-center align-items-center top-btn">
                        <Button
                            outline
                            style={{ borderRadius: 10, width: 245, height: 40 }}
                            id="datepicker"
                            className="d-inline align-items-center justify-content-center popover-btn"
                        >
                            <span>{start}</span> - <span>{end}</span>
                            <BiChevronDown
                                style={{ width: 16, height: 16, marginLeft: 5 }}
                            ></BiChevronDown>
                        </Button>
                        <Popover
                            popperClassName="popover-full-width rounded border-0 card-border "
                            trigger="legacy"
                            target="datepicker"
                            toggle={datetoggle}
                            placement="bottom"
                            isOpen={datepicker}
                        >
                            <DateRangePicker
                                ranges={[selectedDateRange]}
                                onChange={handleSelect}
                                months={2}
                                direction="vertical"
                                fixedHeight={false}
                            />
                            <div
                                className="d-flex justify-content-end mb-2 mr-3"
                                style={{
                                    width: '100%',
                                    padding: '14px',
                                    borderTop: '2px solid #f1f1f3',
                                }}
                            >
                                <Button
                                    className="cancel-btn-modal modals-new-btns"
                                    style={{ marginRight: '8px' }}
                                    onClick={datetoggle}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="save-btn-modal modals-new-btns"
                                    onClick={updateDate}
                                >
                                    Apply
                                </Button>
                            </div>
                        </Popover>{' '}
                        <Button
                            outline
                            style={{ borderRadius: 10, width: 102, height: 40 }}
                            id="filterId"
                            className=" d-flex align-items-center mx-2 popover-btn"
                        >
                            <img src={filter} alt="filter" />
                            Filters
                        </Button>
                        <Popover
                            popperClassName="popover-full-width rounded border-0 mt-2"
                            target="filterId"
                            toggle={() => setFilter(!vehFilter)}
                            placement="bottom"
                            trigger="legacy"
                            isOpen={vehFilter}
                        >
                            <div className="checkbox-container">
                                <div className="px-3">
                                    <h5 className="custmText mb-4">Vehicle category</h5>
                                    {categories.map((cat, i) => (
                                        <FormGroup check className="mb-3">
                                            <Input
                                                className="my-2"
                                                key={i}
                                                type="checkbox"
                                                name="category"
                                                id={cat.title}
                                                value={cat.title}
                                                onChange={handleselect}
                                            />
                                            <Label check className="mt-1 ml-1">
                                                {cat.title}
                                            </Label>
                                        </FormGroup>
                                    ))}
                                    <hr className="w-100" />
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Button
                                            className="cancel-btn-modal modals-new-btns"
                                            style={{ marginRight: '8px' }}
                                            onClick={() => setFilter(!vehFilter)}
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            className="save-btn-modal modals-new-btns"
                                            onClick={saveVehicleCategory}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Popover>
                        {!isPreset && (
                            <div>
                                <Button
                                    outline
                                    style={{ borderRadius: 10, width: 157, height: 40 }}
                                    id="settingId"
                                    className="popover-btn"
                                    onClick={ToggleSidebar}
                                >
                                    <img src={pagesetting} alt="pagesetting" />
                                    Page settings
                                </Button>
                            </div>
                        )}
                        <Button
                            outline
                            style={{ borderRadius: 10, width: 104, height: 40 }}
                            onClick={exportCVS}
                            className="d-flex align-items-center popover-btn mx-1"
                        >
                            <img src={exportdata} alt="export" />
                            Export
                        </Button>
                    </div>
                </div>
                <div className="d-flex justify-content-between text-right">
                    <Modal isOpen={modal} toggle={modalToggle} className="model-position">
                        <ModalBody>
                            {addcard ? (
                                <AddCard
                                    uuid={uuid}
                                    dots={dots}
                                    slug={slug}
                                    setAddcard={setAddcard}
                                    addToggle={modalToggle}
                                    location={list}
                                ></AddCard>
                            ) : (
                                <NewDashViews
                                    openModal={modal}
                                    modalType={modalType}
                                    modalToggle={modalToggle}
                                    uuid={uuid}
                                    createView={createView}
                                    slug={slug}
                                    view={view}
                                    toggle={modalToggle}
                                    ToggleSidebar={ToggleSidebar}
                                    setView={setView}
                                    setId={setId}
                                    setPreset={setPreset}
                                />
                            )}
                        </ModalBody>
                    </Modal>
                </div>
            </div>

            <div className="grid-layout pt-4">
                <div className="card-items no-gutters">
                    {dashitems?.map((item, id) => (
                        <Col key={item.title} className={item.class}>
                            {item.component} {item.title}
                        </Col>
                    ))}
                </div>
                {isOpen ? (
                    <Col md={3} className="d-flex">
                        <div
                            style={{
                                width: '20px',
                                height: '40px',
                                display: 'block',
                                position: 'fixed',
                                right: '0px',
                                bottom: '0px',
                                background: '#fff',
                                marginRight: '390px',
                                marginBottom: '200px',
                            }}
                            className="chevron"
                            onClick={ToggleSidebar}
                        >
                            <span className="chevron-icon">
                                <img
                                    src="/svgs/dashboard-icons/chevronRight.svg"
                                    alt="obstacle-icon"
                                    width="16px"
                                    height="16px"
                                    style={{ cursor: 'pointer' }}
                                />
                            </span>
                        </div>
                        <DashboardPagesetting
                            isOpen={isOpen}
                            openModal={modal}
                            slug={slug}
                            uuid={uuid}
                            save={save}
                            setModalType={setModalType}
                            createView={createView}
                            setSave={setSave}
                            setting={setting}
                            view={view}
                            setView={setView}
                            setSetting={setSetting}
                            setCreateView={setCreateView}
                            settingToggle={settingToggle}
                            list={list}
                            setList={setList}
                            setAddcard={setAddcard}
                            modalToggle={modalToggle}
                            ToggleSidebar={ToggleSidebar}
                        />
                    </Col>
                ) : (
                    <>
                        <NotificationCard />
                        {!isPreset && (
                            <div
                                className="chevron"
                                onClick={ToggleSidebar}
                                style={{
                                    width: '20px',
                                    height: '40px',
                                    display: 'block',
                                    position: 'fixed',
                                    right: '0px',
                                    bottom: '0px',
                                    background: '#fff',
                                    marginRight: '20px',
                                    marginBottom: '240px',
                                }}
                            >
                                <span className="chevron-icon">
                                    <img
                                        src="/svgs/dashboard-icons/chevronLeft.svg"
                                        alt="obstacle-icon"
                                        width="16px"
                                        height="16px"
                                        style={{ cursor: 'pointer' }}
                                    />
                                </span>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
export default DashboardHome
